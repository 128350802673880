import React, { useState } from "react";
import '../Styles/login.scss';
import axiosInstance from "../axiosConfig";
import { useJwt } from "react-jwt";
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
      e.preventDefault();

      try {

        const response = await axiosInstance.post("/login", {
          username,
          password,
        });

        if (response.status === 200 && response.data) {
          console.log("Успешная авторизация");

          localStorage.setItem("token", response.data.token);
          localStorage.setItem("role", response.data.role); 
          navigate("/employee");
        }
      } catch (err) {
        console.error("Ошибка при авторизации:", err);
        setError("Неверное имя пользователя или пароль");
      }
    };



    return (
      <div className="login_start_container">
        <div className="login-page">
          <div className="form">
            <form className="login-form">
              <input
                type="text"
                placeholder="Логин"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <input
                type="password"
                placeholder="Пароль"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button onClick={handleLogin}>войти</button>
            </form>
          </div>
        </div>
      </div>
    );
};

export default Login;