import React, { useState } from "react";
import axiosInstance from "../axiosConfig";

const FileUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState("");

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedFile) {
      setMessage("Пожалуйста, выберите файл.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axiosInstance.post("/excel/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        setMessage("Файл успешно загружен!");
      } else {
        setMessage(`Ошибка загрузки: ${response.statusText}`);
      }
    } catch (error) {
      setMessage(`Ошибка: ${error.message}`);
    }
  };

  return (
    <div>
      <h1>Загрузить Excel файл</h1>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleFileChange} />
        <button type="submit">Загрузить</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default FileUpload;
