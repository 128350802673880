import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface controlState {
  expiringLMKs: number;
  expiredLMKs: number;

  expiringSIZ: number;
  expiredSIZ: number;

  expiringFLG: number;
  expiredFLG: number;

  expiringADSM: number;
  expiredADSM: number;
}

const initialState: controlState = {
  expiringLMKs: 0,
  expiredLMKs: 0,

  expiringSIZ: 0,
  expiredSIZ: 0,

  expiringFLG: 0,
  expiredFLG: 0,

  expiringADSM: 0,
  expiredADSM: 0,
};

const controlSlice = createSlice({
  name: "control",
  initialState,
  reducers: {
    setExpiringLMKs(state, action: PayloadAction<number>) {
      state.expiringLMKs = action.payload;
    },
    setExpiredLMKs(state, action: PayloadAction<number>) {
      state.expiredLMKs = action.payload;
    },

    setExpiringSIZ(state, action: PayloadAction<number>) {
      state.expiringSIZ = action.payload;
    },
    setExpiredSIZ(state, action: PayloadAction<number>) {
      state.expiredSIZ = action.payload;
    },

    setExpiringFLG(state, action: PayloadAction<number>) {
      state.expiringFLG = action.payload;
    },
    setExpiredFLG(state, action: PayloadAction<number>) {
      state.expiredFLG = action.payload;
    },

    setExpiringADSM(state, action: PayloadAction<number>) {
      state.expiringADSM = action.payload;
    },
    setExpiredADSM(state, action: PayloadAction<number>) {
      state.expiredADSM = action.payload;
    },
  },
});

export const { setExpiringLMKs, setExpiredLMKs, setExpiringSIZ, setExpiredSIZ, setExpiringFLG, setExpiredFLG, setExpiringADSM, setExpiredADSM } = controlSlice.actions;
export default controlSlice.reducer;
