import React, { useEffect, useState } from "react";
import axios2 from "../axiosConfig";
import "../Styles/tableSiz.scss";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { ruRU } from "@mui/x-data-grid/locales";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import IconButton from "@mui/material/IconButton";
import { formatDate } from "../models.ts";
import axiosInstance from "../axiosConfig";

const TableSiz = () => {
  const [data, setData] = useState([]);

  const handlePrint = async (id) => {
    try {
      const response = await axiosInstance.get(
        `/documents/siz/${id}`,
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("target", "_blank");
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Ошибка при получении PDF:", error);
    }
  };

  const fetchData = async () => {
    try {
      const sizResponse = await axiosInstance.get("/siz");
      const filteredData = sizResponse.data
        .filter((row) => row.employee.status === true)
        .map((row) => ({
          ...row,
          employee: {
            ...row.employee,
            datesiz: formatDate(row.employee.datesiz),
          },
        }));
      setData(filteredData);
    } catch (error) {
      console.error("Error during GET request for sizes", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns: GridColDef[] = [
    {
      field: "employee",
      headerName: "ФИО",
      type: "string",
      width: 230,
      editable: false,
      valueGetter: (value, row) => {
        if (row.employee) {
          return row.employee.fio;
        }
      },
    },
    {
      field: "datesiz",
      headerName: "Дата",
      width: 100,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (row.employee) {
          const [day, month, year] = row.employee.datesiz.split(".");
          return new Date(`${year}-${month}-${day}`);
        }
      },
    },
    {
      field: "height",
      headerName: "Рост",
      width: 100,
      editable: false,
    },
    {
      field: "clothingsize",
      headerName: "Размер одежды",
      width: 130,
      editable: false,
    },
    {
      field: "shoesize",
      headerName: "Размер обуви",
      width: 130,
      editable: false,
    },
    {
      field: "headdress",
      headerName: "Размер головного убора",
      width: 160,
      editable: false,
    },
    {
      field: "button",
      headerName: "Действия",
      width: 100,
      renderCell: (params) => (
        <div>
          <IconButton onClick={() => handlePrint(params.row.employee.id)}>
            <LocalPrintshopIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
      <div className="siz_cont">
        <div className="table_cont2">
          <DataGrid
            rows={data}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 12,
                },
              },
            }}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            pageSizeOptions={[12]}
            disableRowSelectionOnClick
            slots={{
              toolbar: GridToolbar,
            }}
          />
        </div>
      </div>
  );
};

export default TableSiz;
