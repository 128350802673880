import "../Styles/control.scss";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";
import { useCountUp } from "use-count-up";
import "dayjs/locale/ru";
import { Employee, formatDate2 } from "../models.ts";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState, useCallback } from "react";
import {
  fetchEmployee,
  fetchEmployeesWithFLGExpired,
  fetchEmployeesWithFLGExpiring,
} from "../api_control.ts";
import { useDispatch } from "react-redux";
import { setExpiringFLG, setExpiredFLG } from "../global/controlSlice.ts";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Notification from "../Components/notification.tsx";
import axiosInstance from "../axiosConfig.js";

const ControlFlg = () => {
  const [dataEmployee, setDataEmployee] = useState<Employee[]>([]);
  const [FLGExpiring, setFLGExpiring] = useState<Employee[]>([]);
  const [FLGExpired, setFLGExpired] = useState<Employee[]>([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date());

  const [filteredExpiring, setFilteredExpiring] = useState<Employee[]>([]);
  const [filteredExpired, setFilteredExpired] = useState<Employee[]>([]);

  const [isFiltered, setIsFiltered] = useState(false);

  const [showFirstTable, setShowFirstTable] = useState(true);

  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [selectedEmployeeFio, setSelectedEmployeeFio] = useState("");
  const [befDateFLG, setBefDateFLG] = useState("");
  const [selectedDateFLG, setSelectedDateFLG] = useState("");
  const [isModalVisibleUpd, setIsModalVisibleUpd] = useState(false);

  const [notification, setNotification] = useState({
    status: "",
    title: "",
    subtitle: "",
    isVisible: false,
  });

  const hideNotification = () => {
    setNotification({
      ...notification,
      isVisible: false,
    });
  };

  const handleToggle = () => {
    setShowFirstTable(!showFirstTable);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const rowCountExpired1 = filteredExpired.length;
    const rowCountExpiring1 = filteredExpiring.length;
    dispatch(setExpiringFLG(rowCountExpiring1));
    dispatch(setExpiredFLG(rowCountExpired1));
  }, [dispatch, filteredExpired.length, filteredExpiring.length]);

  const fetchData = async () => {
    try {
      const data1 = await fetchEmployeesWithFLGExpiring();
      setFLGExpiring(data1);
      const data2 = await fetchEmployeesWithFLGExpired();
      setFLGExpired(data2);
      const dataEmpl = await fetchEmployee();
      setDataEmployee(dataEmpl);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filterDataForMonth = useCallback(
    (date: Date) => {
      const month = date.getMonth();

      const expiringFiltered = FLGExpiring.filter((employee) => {
        if (!employee.dateflg) {
          return false;
        }
        const [day, monthStr, year] = employee.dateflg.split(".");
        const lmkDate = new Date(`${year}-${monthStr}-${day}`);
        return lmkDate.getMonth() === month;
      });

      const expiredFiltered = FLGExpired.filter((employee) => {
        if (!employee.dateflg) {
          return false;
        }
        const [day, monthStr, year] = employee.dateflg.split(".");
        const lmkDate = new Date(`${year}-${monthStr}-${day}`);
        return lmkDate.getMonth() === month;
      });

      setFilteredExpiring(expiringFiltered);
      setFilteredExpired(expiredFiltered);
    },
    [FLGExpiring, FLGExpired]
  );

  const handleResetFilter = () => {
    setIsFiltered(false);
  };

  useEffect(() => {
    if (isFiltered && (FLGExpiring.length > 0 || FLGExpired.length > 0)) {
      filterDataForMonth(selectedMonth);
    } else {
      setFilteredExpiring(FLGExpiring);
      setFilteredExpired(FLGExpired);
    }
  }, [selectedMonth, FLGExpiring, FLGExpired, isFiltered, filterDataForMonth]);

  const handleMonthChange = (direction: "next" | "prev") => {
    setIsFiltered(true);
    setSelectedMonth(() => {
      const newMonth = new Date();
      if (direction === "next") {
        newMonth.setMonth(newMonth.getMonth() + 1);
      }
      return newMonth;
    });
  };

  const rowCountExpired = filteredExpired.length;
  const rowCountExpiring = filteredExpiring.length;
  const rowCountAll = dataEmployee ? dataEmployee.length : 0;

  const procExpired = Math.round((rowCountExpired / rowCountAll) * 100);
  const procExpiring = Math.round((rowCountExpiring / rowCountAll) * 100);

  const { value: value1 } = useCountUp({
    isCounting: true,
    duration: 1,
    start: 0,
    end: procExpired,
  });

  const { value: value2 } = useCountUp({
    isCounting: true,
    duration: 1,
    start: 0,
    end: procExpiring,
  });

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 40 },
    {
      field: "fio",
      headerName: "ФИО",
      width: 250,
      type: "string",
      editable: false,
    },
    {
      field: "dateflg",
      headerName: "Дата прохождения ФЛГ",
      width: 200,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.dateflg) {
          return null;
        }
        const [day, month, year] = row.dateflg.split(".");
        return new Date(`${year}-${month}-${day}`);
      },
    },
    {
      field: "dateflg2",
      headerName: "Дата срока окончания ФЛГ",
      width: 200,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.dateflg) {
          return null;
        }
        const [day, month, year] = row.dateflg.split(".");
        const startDate = new Date(`${year}-${month}-${day}`);
        const endDate = new Date(startDate);
        endDate.setFullYear(endDate.getFullYear() + 1);
        return endDate;
      },
    },
    {
      field: "button",
      headerName: "",
      width: 40,
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() =>
              handleEdit(params.row.id, params.row.fio, params.row.dateflg)
            }
          >
            <EditIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  // Редактирование
  const handleEdit = (id, fio, date) => {
    setSelectedEmployeeId(id);
    setSelectedEmployeeFio(fio);
    const [day, monthStr, year] = date.split(".");
    const flgDate = new Date(`${year}-${monthStr}-${day}`);
    setBefDateFLG(formatDate2(flgDate));
    setSelectedDateFLG(formatDate2(flgDate));
    setIsModalVisibleUpd(true);
  };

  const confirmUpdFLG = async () => {
    if (!selectedDateFLG) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Введите дату",
        isVisible: true,
      });
      return;
    }
    if (selectedDateFLG === befDateFLG) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Новая дата имеет такое же значение",
        isVisible: true,
      });
      return;
    }
    try {
      const formattedDateFLG = new Date(selectedDateFLG).toISOString();
      const flgData = {
        dateflg: formattedDateFLG,
      };
      await axiosInstance.put(`/employees/${selectedEmployeeId}`, flgData);
      setNotification({
        status: "success",
        title: "Успешно",
        subtitle: "Данные ЛМК обновлены!",
        isVisible: true,
      });
      setIsModalVisibleUpd(false);
      fetchData();
    } catch {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Не удалось изменить данные. Ошибка сервера",
        isVisible: true,
      });
    }
  };

  return (
    <div>
      <div className="control_container">
        <div className="navbar_control">
          <div className="cntrl">
            <div className="data_cntrl">
              <div className="title_cntrl">ФЛГ в течении 2х месяцев</div>
              <div className="num_cntrl">{rowCountExpiring}</div>
            </div>
            <CircularProgress
              determinate
              value={value2 as number}
              sx={{
                "--CircularProgress-size": "78px",
                "--CircularProgress-progressColor": "#ffaa00",
              }}
            >
              <Typography>{value2}%</Typography>
            </CircularProgress>
          </div>
          <div className="cntrl">
            <div className="data_cntrl">
              <div className="title_cntrl">ФЛГ просрочена</div>
              <div className="num_cntrl" style={{ color: "#ad1100" }}>
                {rowCountExpired}
              </div>
            </div>
            <CircularProgress
              determinate
              value={value1 as number}
              sx={{
                "--CircularProgress-size": "78px",
                "--CircularProgress-progressColor": "#ad1100",
              }}
            >
              <Typography>{value1}%</Typography>
            </CircularProgress>
          </div>
        </div>
        <div className="btn_m">
          <Button onClick={() => handleMonthChange("prev")} variant="contained">
            Текущий месяц
          </Button>
          <Button onClick={() => handleMonthChange("next")} variant="contained">
            Следующий месяц
          </Button>
          <Button onClick={handleResetFilter} variant="contained">
            Все месяца
          </Button>
        </div>
        <Button
          variant="contained"
          onClick={handleToggle}
          style={{ marginBottom: "20px" }}
        >
          {showFirstTable
            ? "Переключиться на данные с просроченной ФЛГ"
            : "Переключиться на ближайшие ФЛГ"}
        </Button>
        <div className="table_cont_control">
          {showFirstTable ? (
            <div className="table_employed">
              <DataGrid
                rows={filteredExpiring ?? []}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
              />
            </div>
          ) : (
            <div className="table_employed">
              <DataGrid
                rows={filteredExpired ?? []}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
              />
            </div>
          )}
        </div>
      </div>
      {isModalVisibleUpd && (
        <div className="modal_upd_lmk">
          <div className="modal_cont_upd_lmk">
            <label style={{ marginBottom: "10px" }}>
              ФИО: {selectedEmployeeFio}
            </label>

            <label style={{ marginBottom: "10px" }}>
              Дата прохождения ФЛГ:
            </label>
            <input
              type="date"
              value={selectedDateFLG}
              onChange={(e) => setSelectedDateFLG(e.target.value)}
            />

            <div
              style={{
                display: "flex",
                marginTop: "0px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button onClick={confirmUpdFLG}>Редактировать</button>
              <button onClick={() => setIsModalVisibleUpd(false)}>
                Отмена
              </button>
            </div>
          </div>
        </div>
      )}
      {notification.isVisible && (
        <Notification
          status={notification.status}
          title={notification.title}
          subtitle={notification.subtitle}
          onClose={hideNotification}
          isVisible={notification.isVisible}
        />
      )}
    </div>
  );
};

export default ControlFlg;