import "../Styles/control.scss";
import CircularProgress from "@mui/joy/CircularProgress";
import Typography from "@mui/joy/Typography";
import { useCountUp } from "use-count-up";
import "dayjs/locale/ru";
import { Employee, formatDate2 } from "../models.ts";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState, useCallback } from "react";
import {
  fetchEmployee,
  fetchEmployeesWithSIZExpiring,
  fetchEmployeesWithSIZExpired,
} from "../api_control.ts";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { setExpiringSIZ, setExpiredSIZ } from "../global/controlSlice.ts";
import Notification from "../Components/notification.tsx";
import axiosInstance from "../axiosConfig.js";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";

const ControlSiz = () => {
  const [dataEmployee, setDataEmployee] = useState<Employee[]>([]);
  const [SIZExpiring, setSIZExpiring] = useState<Employee[]>([]);
  const [SIZExpired, setSIZExpired] = useState<Employee[]>([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date());

  const [filteredExpiring, setFilteredExpiring] = useState<Employee[]>([]);
  const [filteredExpired, setFilteredExpired] = useState<Employee[]>([]);

  const [isFiltered, setIsFiltered] = useState(false);

  const [showFirstTable, setShowFirstTable] = useState(true);

  const [sizId, setSizId] = useState([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [selectedEmployeeFio, setSelectedEmployeeFio] = useState("");
  const [befDateSIZ, setBefDateSIZ] = useState("");
  const [selectedDateSIZ, setSelectedDateSIZ] = useState("");
  const [isModalVisibleUpd, setIsModalVisibleUpd] = useState(false);

  const [notification, setNotification] = useState({
    status: "",
    title: "",
    subtitle: "",
    subtitle2: <div></div>,
    isVisible: false,
  });

  const [height, setHeight] = useState("");
  const [clothingsize, setClothingSize] = useState("");
  const [shoesize, setShoeSize] = useState("");
  const [headdress, setHeaddress] = useState("");
  const [shiftOptions, setShiftOptions] = useState([]);
  const [postOptions, setPostOptions] = useState([]);

  const heightOptions = ["158-164", "170-176", "176-180", "182-186"];
  const shoesizeOptions = [
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
  ];
  const clothingsizeOptions = [
    "42-44",
    "44-46",
    "46-48",
    "48-50",
    "50-52",
    "52-54",
    "54-56",
    "58-60",
  ];
  const headdressOptions = ["44", "46", "48", "50", "52", "54", "56", "58"];

  const hideNotification = () => {
    setNotification({
      ...notification,
      isVisible: false,
    });
  };

  const handleToggle = () => {
    setShowFirstTable(!showFirstTable);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const rowCountExpired1 = filteredExpired.length;
    const rowCountExpiring1 = filteredExpiring.length;
    dispatch(setExpiringSIZ(rowCountExpiring1));
    dispatch(setExpiredSIZ(rowCountExpired1));
  }, [dispatch, filteredExpired.length, filteredExpiring.length]);

  const fetchData = async () => {
    try {
      const data1 = await fetchEmployeesWithSIZExpiring();
      setSIZExpiring(data1);
      const data2 = await fetchEmployeesWithSIZExpired();
      setSIZExpired(data2);
      const dataEmpl = await fetchEmployee();
      setDataEmployee(dataEmpl);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    axiosInstance
      .get("/shifts")
      .then((response) => setShiftOptions(response.data))
      .catch((error) => console.error("Error fetching shift options:", error));

    axiosInstance
      .get("/posts")
      .then((response) => setPostOptions(response.data))
      .catch((error) => console.error("Error fetching post options:", error));
  }, []);

  const filterDataForMonth = useCallback(
    (date: Date) => {
      const month = date.getMonth();

      const expiringFiltered = SIZExpiring.filter((employee) => {
        if (!employee.datesiz) {
          return false;
        }
        const [day, monthStr, year] = employee.datesiz.split(".");
        const lmkDate = new Date(`${year}-${monthStr}-${day}`);
        return lmkDate.getMonth() === month;
      });

      const expiredFiltered = SIZExpired.filter((employee) => {
        if (!employee.datesiz) {
          return false;
        }
        const [day, monthStr, year] = employee.datesiz.split(".");
        const lmkDate = new Date(`${year}-${monthStr}-${day}`);
        return lmkDate.getMonth() === month;
      });

      setFilteredExpiring(expiringFiltered);
      setFilteredExpired(expiredFiltered);
    },
    [SIZExpiring, SIZExpired]
  );

  const handleResetFilter = () => {
    setIsFiltered(false);
  };

  useEffect(() => {
    if (isFiltered && (SIZExpiring.length > 0 || SIZExpired.length > 0)) {
      filterDataForMonth(selectedMonth);
    } else {
      setFilteredExpiring(SIZExpiring);
      setFilteredExpired(SIZExpired);
    }
  }, [selectedMonth, SIZExpiring, SIZExpired, isFiltered, filterDataForMonth]);

  const handleMonthChange = (direction: "next" | "prev") => {
    setIsFiltered(true);
    setSelectedMonth(() => {
      const newMonth = new Date();
      if (direction === "next") {
        newMonth.setMonth(newMonth.getMonth() + 1);
      }
      return newMonth;
    });
  };

  const rowCountExpired = filteredExpired.length;
  const rowCountExpiring = filteredExpiring.length;
  const rowCountAll = dataEmployee ? dataEmployee.length : 0;

  const procExpired = Math.round((rowCountExpired / rowCountAll) * 100);
  const procExpiring = Math.round((rowCountExpiring / rowCountAll) * 100);

  const { value: value1 } = useCountUp({
    isCounting: true,
    duration: 1,
    start: 0,
    end: procExpired,
  });

  const { value: value2 } = useCountUp({
    isCounting: true,
    duration: 1,
    start: 0,
    end: procExpiring,
  });

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 40 },
    {
      field: "fio",
      headerName: "ФИО",
      width: 250,
      type: "string",
      editable: false,
    },
    {
      field: "datesiz",
      headerName: "Дата выдачи СИЗ",
      width: 200,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.datesiz) {
          return null;
        }
        const [day, month, year] = row.datesiz.split(".");
        return new Date(`${year}-${month}-${day}`);
      },
    },
    {
      field: "datesiz2",
      headerName: "Повторная выдача СИЗ",
      width: 200,
      editable: false,
      type: "date",
      valueGetter: (value, row) => {
        if (!row.datesiz) {
          return null;
        }
        const [day, month, year] = row.datesiz.split(".");
        const startDate = new Date(`${year}-${month}-${day}`);
        const endDate = new Date(startDate);
        endDate.setFullYear(endDate.getFullYear() + 1);
        return endDate;
      },
    },
    {
      field: "button",
      headerName: "",
      width: 40,
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() =>
              handleEdit(params.row.id, params.row.fio, params.row.datesiz)
            }
          >
            <EditIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  // Редактирование
  const handleEdit = async (id, fio, date) => {
    setSelectedEmployeeId(id);
    setSelectedEmployeeFio(fio);
    const [day, monthStr, year] = date.split(".");
    const sizDate = new Date(`${year}-${monthStr}-${day}`);
    setBefDateSIZ(formatDate2(sizDate));
    setSelectedDateSIZ(formatDate2(sizDate));
    await axiosInstance
      .get(`/siz/employee/${id}`)
      .then((response) => {
        const siz = response.data;
        setSizId(siz.Id);
        setHeight(siz.height);
        setClothingSize(siz.clothingsize);
        setShoeSize(siz.shoesize);
        setHeaddress(siz.headdress);
      })
      .catch((error) => {
        console.error(
          "Ошибка при получении данных о сотруднике для редактирования:",
          error
        );
      });
    setIsModalVisibleUpd(true);
  };

  const confirmUpdLMK = async () => {
    if (!selectedDateSIZ) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Введите дату",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }
    if (selectedDateSIZ === befDateSIZ) {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Новая дата имеет такое же значение",
        subtitle2: <div></div>,
        isVisible: true,
      });
      return;
    }
    try {
      const formattedDateSIZ = new Date(selectedDateSIZ).toISOString();
      const sizData = {
        datesiz: formattedDateSIZ,
      };
      const updatedSizData = {
        id: sizId,
        employee: { id: selectedEmployeeId },
        height: height,
        shoesize: shoesize,
        clothingsize: clothingsize,
        headdress: headdress,
      };

      await updateSiz(updatedSizData);
      await updateEmpl(sizData);
  
      setNotification({
        status: "success",
        title: "Успешно",
        subtitle: "Данные СИЗ обновлены!",
        subtitle2: (
          <div>
            <p>Напечатайте новую карточку СИЗ!</p>
            <Button
              variant="contained"
              onClick={() => handlePrintDoc("siz", selectedEmployeeId)}
              style={{ marginTop: "10px" }}
            >
              карточка СИЗ
            </Button>
          </div>
        ),
        isVisible: true,
      });
      setIsModalVisibleUpd(false);
      fetchData();
    } catch {
      setNotification({
        status: "error",
        title: "Ошибка",
        subtitle: "Не удалось изменить данные. Ошибка сервера",
        subtitle2: <div></div>,
        isVisible: true,
      });
    }
  };

  const handlePrintDoc = async (documentType, employeeid) => {
    try {
      const response = await axiosInstance.get(
        `/documents/${documentType}/${employeeid}`,
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("target", "_blank");
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Ошибка при получении PDF:", error);
    }
  };

  const updateSiz = async (updatedSizData) => {
    try {
      const response = await axiosInstance.put("/siz/update", updatedSizData);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const updateEmpl = async (updatedData) => {
    try {
      const response = await axiosInstance.put(
        `/employees/${selectedEmployeeId}`,
        updatedData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  return (
    <div>
      <div className="control_container">
        <div className="navbar_control">
          <div className="cntrl">
            <div className="data_cntrl">
              <div className="title_cntrl">СИЗ в течении 2х месяцев</div>
              <div className="num_cntrl">{rowCountExpiring}</div>
            </div>
            <CircularProgress
              determinate
              value={value2 as number}
              sx={{
                "--CircularProgress-size": "78px",
                "--CircularProgress-progressColor": "#ffaa00",
              }}
            >
              <Typography>{value2}%</Typography>
            </CircularProgress>
          </div>
          <div className="cntrl">
            <div className="data_cntrl">
              <div className="title_cntrl">СИЗ просрочены</div>
              <div className="num_cntrl" style={{ color: "#ad1100" }}>
                {rowCountExpired}
              </div>
            </div>
            <CircularProgress
              determinate
              value={value1 as number}
              sx={{
                "--CircularProgress-size": "78px",
                "--CircularProgress-progressColor": "#ad1100",
              }}
            >
              <Typography>{value1}%</Typography>
            </CircularProgress>
          </div>
        </div>
        <div className="btn_m">
          <Button onClick={() => handleMonthChange("prev")} variant="contained">
            Текущий месяц
          </Button>
          <Button onClick={() => handleMonthChange("next")} variant="contained">
            Следующий месяц
          </Button>
          <Button onClick={handleResetFilter} variant="contained">
            Все месяца
          </Button>
        </div>
        <Button
          variant="contained"
          onClick={handleToggle}
          style={{ marginBottom: "20px" }}
        >
          {showFirstTable
            ? "Переключиться на данные с просроченными СИЗ"
            : "Переключиться на ближайшие СИЗ"}
        </Button>
        <div className="table_cont_control">
          {showFirstTable ? (
            <div className="table_employed">
              <DataGrid
                rows={filteredExpiring ?? []}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
              />
            </div>
          ) : (
            <div className="table_employed">
              <DataGrid
                rows={filteredExpired ?? []}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
              />
            </div>
          )}
        </div>
      </div>
      {isModalVisibleUpd && (
        <div className="modal_upd_siz">
          <div className="modal_cont_upd_siz">
            <h3>СИЗ</h3>

            <label style={{ marginBottom: "10px" }}>
              ФИО: {selectedEmployeeFio}
            </label>

            <div>
              <label htmlFor="height-dropdown" style={{ marginBottom: "10px" }}>
                Рост:
              </label>
              <select
                id="height-dropdown"
                value={height}
                style={{ width: "240px", marginBottom: "10px" }}
                onChange={(e) => setHeight(e.target.value)}
              >
                <option value="" disabled>
                  Выберите рост
                </option>
                {heightOptions.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label
                htmlFor="clothingsize-dropdown"
                style={{ marginBottom: "10px" }}
              >
                Размер одежды:
              </label>
              <select
                id="clothingsize-dropdown"
                value={clothingsize}
                style={{ width: "142px", marginBottom: "10px" }}
                onChange={(e) => setClothingSize(e.target.value)}
              >
                <option value="" disabled>
                  Выберите размер
                </option>
                {clothingsizeOptions.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="size-dropdown" style={{ marginBottom: "10px" }}>
                Размер обуви:
              </label>
              <select
                id="size-dropdown"
                value={shoesize}
                style={{ width: "158px", marginBottom: "10px" }}
                onChange={(e) => setShoeSize(e.target.value)}
              >
                <option value="" disabled>
                  Выберите размер
                </option>
                {shoesizeOptions.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label
                htmlFor="headdress-dropdown"
                style={{ marginBottom: "10px" }}
              >
                Размер головного убора:
              </label>
              <select
                id="headdress-dropdown"
                value={headdress}
                style={{ width: "67px", marginBottom: "10px" }}
                onChange={(e) => setHeaddress(e.target.value)}
              >
                <option value="" disabled>
                  Выберите размер
                </option>
                {headdressOptions.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>

            <label style={{ marginBottom: "10px" }}>
              Дата прохождения СИЗ:
            </label>
            <input
              type="date"
              value={selectedDateSIZ}
              onChange={(e) => setSelectedDateSIZ(e.target.value)}
              style={{height: "35px"}}
            />

            <div
              style={{
                display: "flex",
                marginTop: "0px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button onClick={confirmUpdLMK}>Редактировать</button>
              <button onClick={() => setIsModalVisibleUpd(false)}>
                Отмена
              </button>
            </div>
          </div>
        </div>
      )}
      {notification.isVisible && (
        <Notification
          status={notification.status}
          title={notification.title}
          subtitle={notification.subtitle}
          subtitle2={notification.subtitle2}
          onClose={hideNotification}
          isVisible={notification.isVisible}
        />
      )}
    </div>
  );
};

export default ControlSiz;
