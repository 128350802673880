import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Employee } from "../models";

interface EmployeeState {
  employee: Employee | null;
}

const initialState: EmployeeState = {
  employee: null,
};

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    setEmployee(state, action: PayloadAction<Employee>) {
      state.employee = action.payload;
    },
    clearEmployee(state) {
      state.employee = null;
    },
  },
});

export const { setEmployee, clearEmployee } = employeeSlice.actions;
export const employeeReducer = employeeSlice.reducer;