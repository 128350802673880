import { configureStore } from "@reduxjs/toolkit";
import controlReducer from "./controlSlice.ts";

const store = configureStore({
  reducer: {
    lmk: controlReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export default store;