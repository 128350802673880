import React, { useState, useEffect, useCallback } from "react";
import InputMask from "react-input-mask";
import "../Styles/employee.scss";
import axiosInstance from "../axiosConfig.js";
import Notification from "../Components/notification.tsx";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setEmployee } from "../slices/employeeSlice.ts";
import { AppState } from "../store.ts";

const ModalAdd = ({ closeModalAdd, onAddSuccess }) => {
    // поля модального окна
    const [fio, setFio] = useState("");
    const [gender, setGender] = useState("");
    const [datebirthday, setDateBirthday] = useState("");
    const [phone, setPhone] = useState("");
    const [shift, setShift] = useState("");
    const [post, setPost] = useState("");
    const [dateemployment, setDateEmployment] = useState(() => {
      const today = new Date();
      return today.toISOString().split("T")[0];
    });
    const [electro, setElectro] = useState("");
    const [height, setHeight] = useState("");
    const [clothingsize, setClothingSize] = useState("");
    const [shoesize, setShoeSize] = useState("");
    const [headdress, setHeaddress] = useState("");

    // Данные по должностям и сменам
    const [shiftOptions, setShiftOptions] = useState([]);
    const [postOptions, setPostOptions] = useState([]);

    // Передача данных о сотруднике на следующий этап (обучение)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Настройки всплывающего окна
    const [notification, setNotification] = useState({
        status: "",
        title: "",
        subtitle: "",
        isVisible: false,
        subtitle2: <div></div>
    });

    const hideNotification = () => {
      setNotification({
        ...notification,
        isVisible: false,
      });
      closeModalAdd();
      onAddSuccess();
    };

    // Получение должностей и смен
    const fetchData = useCallback(async () => {
        try {
        const shiftsResponse = await axiosInstance.get("/shifts");
        const postsResponse = await axiosInstance.get("/posts");
        setShiftOptions(shiftsResponse.data);
        setPostOptions(postsResponse.data);
        } catch (error) {
        console.error("Ошибка получения данных о сменах и должностей:", error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    // Данные по СИЗ
    const heightOptions = ["158-164", "170-176", "176-180", "182-186"];
    const shoesizeOptions = [
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
    ];
    const clothingsizeOptions = [
        "42-44",
        "44-46",
        "46-48",
        "48-50",
        "50-52",
        "52-54",
        "54-56",
        "58-60",
    ];
    const headdressOptions = ["44", "46", "48", "50", "52", "54", "56", "58"];

    // Проверка, что все поля заполнены
    const isSaveDisabled = !(
      fio &&
      gender &&
      datebirthday &&
      phone &&
      shift &&
      post &&
      dateemployment &&
      height &&
      clothingsize &&
      shoesize &&
      headdress &&
      electro
    );

    // Добавление сотрудника
    const handleSave = async () => {
      const formattedDateBirthday = new Date(datebirthday).toISOString();
      const formattedDateEmpl = new Date(dateemployment).toISOString();

      const empData = {
        fio: fio,
        gender: gender,
        datebirthday: formattedDateBirthday,
        phone: phone,
        shiftid: shift,
        postid: post,
        dateemployment: formattedDateEmpl,
        numelectrosecurity: electro,
      };

      try {
        const response = await axiosInstance.post("employees", empData);
        if (response.status === 409) {
          setNotification({
            status: "error",
            title: "Ошибка",
            subtitle: "Произошла ошибка",
            subtitle2: <div></div>,
            isVisible: true,
          });
        } else {
          const selectedEmployeeId = response.data.id;

          const sizData = {
            employeeid: selectedEmployeeId,
            height: height,
            clothingsize: clothingsize,
            shoesize: shoesize,
            headdress: headdress,
          };

          await axiosInstance.post(`/siz/${sizData.employeeid}`, sizData);

          const emplLast = response.data;
          const emplNext = {
            id: emplLast.id,
            fio: emplLast.fio,
            gender: emplLast.gender,
            datebirthday: emplLast.datebirthday,
            phone: emplLast.phone,
            shift: emplLast.shift,
            mainPost: emplLast.mainPost,
            dateemployment: emplLast.dateemployment,
            datelmk: emplLast.datelmk,
            dateflg: emplLast.dateflg,
            dateadsm: emplLast.dateadsm,
            datesiz: emplLast.datesiz,
            dateinst: emplLast.dateinst,
            status: emplLast.status,
            datechange: emplLast.datechange,
            dateonjob: null,
            datefire: null,
            dateelectrosecurity: null,
            numelectrosecurity: 0,
          };

          dispatch(setEmployee(emplNext));
          closeModalAdd();
          fetchData();
          onAddSuccess();
          navigate("/area", { state: { showModal: true } });
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          setNotification({
            status: "error",
            title: "Ошибка",
            subtitle:
              "Сотрудник с такими данными уже существует. Пожалуйста, воспользуйтесь редактированием",
            subtitle2: <div></div>,
            isVisible: true,
          });
        } else {
          setNotification({
            status: "error",
            title: "Ошибка",
            subtitle: "Произошла ошибка. Пожалуйста, попробуйте снова.",
            subtitle2: <div></div>,
            isVisible: true,
          });
        }
      }
    };

    return (
      <div>
        <div className="modal">
          <div className="modal-content-empl">
            <span className="close" onClick={closeModalAdd}>
              &times;
            </span>
            <div className="pers_info">
              <h3>Личная информация</h3>

              <label htmlFor="fio">ФИО</label>
              <input
                type="text"
                name="fio"
                value={fio}
                onChange={(e) => setFio(e.target.value)}
              ></input>

              <div className="gen">
                <input
                  type="radio"
                  id="male"
                  name="gender"
                  value="М"
                  checked={gender === "М"}
                  onChange={(e) => setGender(e.target.value)}
                />
                <label htmlFor="male">Мужской</label>
                <input
                  type="radio"
                  id="female"
                  name="gender"
                  value="Ж"
                  checked={gender === "Ж"}
                  onChange={(e) => setGender(e.target.value)}
                />
                <label htmlFor="female">Женский</label>
              </div>

              <label htmlFor="datebirthday">Дата рождения:</label>
              <input
                type="date"
                id="datebirthday"
                name="datebirthday"
                value={datebirthday}
                onChange={(e) => setDateBirthday(e.target.value)}
              />

              <label htmlFor="phone" style={{ marginBottom: "10px" }}>
                Номер телефона:
              </label>
              <InputMask
                mask="+7 999 999 99-99"
                maskChar="_"
                placeholder="+7 ___ ___ __-__"
                id="phone"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                style={{ width: "130px", marginBottom: "10px" }}
              />

              <label htmlFor="shift-dropdown" style={{ marginBottom: "10px" }}>
                Смена:
              </label>
              <select
                id="shift-dropdown"
                value={shift}
                style={{ width: "225px", marginBottom: "10px" }}
                onChange={(e) => setShift(e.target.value)}
              >
                <option value="" disabled>
                  Выберите смену
                </option>
                {shiftOptions.map((option: any) => (
                  <option key={option.id} value={option.id}>
                    {option.nameshift}
                  </option>
                ))}
              </select>

              <label htmlFor="post-dropdown" style={{ marginBottom: "10px" }}>
                Должность:
              </label>
              <select
                id="post-dropdown"
                value={post}
                style={{ width: "185px", marginBottom: "10px" }}
                onChange={(e) => setPost(e.target.value)}
              >
                <option value="" disabled>
                  Выберите должность
                </option>
                {postOptions.map((option: any) => (
                  <option key={option.id} value={option.id}>
                    {option.namepost}
                  </option>
                ))}
              </select>

              <label
                htmlFor="electro-dropdown"
              >
                Группа электробезопасности:
              </label>
              <select
                id="electro-dropdown"
                value={electro}
                style={{ width: "100%", marginBottom: "10px", marginTop: "10px"}}
                onChange={(e) => setElectro(e.target.value)}
              >
                <option value="" disabled>
                  Выберите группу электробезопасности
                </option>
                <option value="1" key="1" >
                  Первая
                </option>
                <option value="2" key="2">
                  Вторая
                </option>
              </select>

              <label htmlFor="dateemployment">Дата трудоустройства:</label>
              <input
                type="date"
                id="dateemployment"
                name="dateemployment"
                value={dateemployment}
                onChange={(e) => setDateEmployment(e.target.value)}
              />

              <h3>СИЗ</h3>

              <label htmlFor="height-dropdown" style={{ marginBottom: "10px" }}>
                Рост:
              </label>
              <select
                id="height-dropdown"
                value={height}
                style={{ width: "240px", marginBottom: "10px" }}
                onChange={(e) => setHeight(e.target.value)}
              >
                <option value="" disabled>
                  Выберите рост
                </option>
                {heightOptions.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>

              <label
                htmlFor="clothingsize-dropdown"
                style={{ marginBottom: "10px" }}
              >
                Размер одежды:
              </label>
              <select
                id="clothingsize-dropdown"
                value={clothingsize}
                style={{ width: "142px", marginBottom: "10px" }}
                onChange={(e) => setClothingSize(e.target.value)}
              >
                <option value="" disabled>
                  Выберите размер
                </option>
                {clothingsizeOptions.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>

              <label htmlFor="size-dropdown" style={{ marginBottom: "10px" }}>
                Размер обуви:
              </label>
              <select
                id="size-dropdown"
                value={shoesize}
                style={{ width: "158px", marginBottom: "10px" }}
                onChange={(e) => setShoeSize(e.target.value)}
              >
                <option value="" disabled>
                  Выберите размер
                </option>
                {shoesizeOptions.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>

              <label
                htmlFor="headdress-dropdown"
                style={{ marginBottom: "10px" }}
              >
                Размер головного убора:
              </label>
              <select
                id="headdress-dropdown"
                value={headdress}
                style={{ width: "67px", marginBottom: "10px" }}
                onChange={(e) => setHeaddress(e.target.value)}
              >
                <option value="" disabled>
                  Выберите размер
                </option>
                {headdressOptions.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>

              <button
                className="add_send_emploee"
                onClick={handleSave}
                disabled={isSaveDisabled}
              >
                Сохранить
              </button>
            </div>
            {notification.isVisible && (
              <Notification
                status={notification.status}
                title={notification.title}
                subtitle={notification.subtitle}
                subtitle2={notification.subtitle2}
                onClose={hideNotification}
                isVisible={notification.isVisible}
              />
            )}
          </div>
        </div>
      </div>
    );
};

export default ModalAdd;
