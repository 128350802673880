import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "../Styles/navbar.scss";
import { useNavigate } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { FaHome, FaTable, FaCalendarAlt } from "react-icons/fa";
import { PiStudentBold } from "react-icons/pi";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { ImExit } from "react-icons/im";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import PrintIcon from "@mui/icons-material/Print";
import HistoryIcon from "@mui/icons-material/History";
import {
  fetchEmployeesWithLMKExpired,
  fetchEmployeesWithLMKExpiring,
  fetchEmployeesWithSIZExpired,
  fetchEmployeesWithSIZExpiring,
  fetchEmployeesWithADSMExpired,
  fetchEmployeesWithADSMExpiring,
  fetchEmployeesWithFLGExpired,
  fetchEmployeesWithFLGExpiring,
  fetchEmployeesWithOnJobExpired,
  fetchEmployeesWithOnJobExpiring,
  fetchEmployeesWithFireExpired,
  fetchEmployeesWithFireExpiring,
  fetchEmployeesWithElectroExpired,
  fetchEmployeesWithElectroExpiring,
} from "../api_control.ts";
import { FileUpload } from "@mui/icons-material";
import axiosInstance from "../axiosConfig.js";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = React.useState(false);

  const handleLogout = async () => {
    try {
      //await axiosInstance.post("/logout");
      localStorage.removeItem("token");
      navigate("/login");
    } catch (error) {
      console.log("Не удалось выйти");
    }
  };

  //ЛМК
  const [expiringLMK, setExpiringLMK] = useState<number | null>(null);
  const [expiredLMK, setExpiredLMK] = useState<number | null>(null);

  //СИЗ
  const [expiringSIZ, setExpiringSIZ] = useState<number | null>(null);
  const [expiredSIZ, setExpiredSIZ] = useState<number | null>(null);

  //ФЛГ
  const [expiringFLG, setExpiringFLG] = useState<number | null>(null);
  const [expiredFLG, setExpiredFLG] = useState<number | null>(null);

  //АДСМ
  const [expiringADSM, setExpiringADSM] = useState<number | null>(null);
  const [expiredADSM, setExpiredADSM] = useState<number | null>(null);

  //Инструктажи
  const [expiringINST, setExpiringINST] = useState<number | null>(null);
  const [expiredINST, setExpiredINST] = useState<number | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // //ЛМК
        const data1 = await fetchEmployeesWithLMKExpiring();
        setExpiringLMK(data1.length);
        const data2 = await fetchEmployeesWithLMKExpired();
        setExpiredLMK(data2.length);

        // //СИЗ
        const data3 = await fetchEmployeesWithSIZExpiring();
        setExpiringSIZ(data3.length);
        const data4 = await fetchEmployeesWithSIZExpired();
        setExpiredSIZ(data4.length);

        // //ФЛГ
        const data5 = await fetchEmployeesWithFLGExpiring();
        setExpiringFLG(data5.length);
        const data6 = await fetchEmployeesWithFLGExpired();
        setExpiredFLG(data6.length);

        // //АДСМ
        const data7 = await fetchEmployeesWithADSMExpiring();
        setExpiringADSM(data7.length);
        const data8 = await fetchEmployeesWithADSMExpired();
        setExpiredADSM(data8.length);

        //Инструктажи
        const data9 = await fetchEmployeesWithOnJobExpiring();
        const data10 = await fetchEmployeesWithOnJobExpired();
        const data11 = await fetchEmployeesWithFireExpiring();
        const data12 = await fetchEmployeesWithFireExpired();
        const data13 = await fetchEmployeesWithElectroExpiring();
        const data14 = await fetchEmployeesWithElectroExpired();
        const instr_ing = data9.length + data11.length + data13.length;
        const instr_ed = data10.length + data12.length + data14.length;
        setExpiringINST(instr_ing);
        setExpiredINST(instr_ed);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [collapsed, location.pathname]);

  return (
    <div>
      {!collapsed && <div style={{ width: "262px" }}></div>}
      {collapsed && <div style={{ width: "82px" }}></div>}
      <div
        style={{
          position: "fixed",
          height: "100%",
          minHeight: "400px",
          scrollbarWidth: "thin",
          overflowY: "auto",
        }}
      >
        <Sidebar collapsed={collapsed}>
          <Menu>
            <MenuItem
              icon={collapsed ? <IoIosArrowForward /> : <IoIosArrowBack />}
              onClick={() => setCollapsed(!collapsed)}
            >
              {collapsed ? "Развернуть" : "Свернуть"}
            </MenuItem>
            <MenuItem icon={<FaHome />} component={<Link to="/" />}>
              Главная
            </MenuItem>
            <SubMenu defaultOpen label="Таблицы" icon={<FaTable />}>
              <MenuItem component={<Link to="/employee" />}>
                {" "}
                Сотрудники
              </MenuItem>
              {/* <MenuItem component={<Link to="/fired_employee" />}>
              {" "}
              Уволенные
            </MenuItem> */}
              <MenuItem component={<Link to="/siz" />}>СИЗ</MenuItem>
              <MenuItem component={<Link to="/lmk" />}>ЛМК</MenuItem>
              <MenuItem component={<Link to="/area_table" />}>Участки</MenuItem>
              <MenuItem component={<Link to="/employed" />}>
                Трудоустроенные
              </MenuItem>
            </SubMenu>
            <MenuItem icon={<PiStudentBold />} component={<Link to="/area" />}>
              Обучение
            </MenuItem>
            <SubMenu
              defaultOpen
              label="Контроль сроков"
              icon={<FaCalendarAlt />}
            >
              <MenuItem component={<Link to="/control" />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  ЛМК
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "20px",
                      marginLeft: "auto",
                      marginRight: "0px",
                    }}
                  >
                    {expiringLMK !== 0 && (
                      <div
                        style={{
                          padding: "1px",
                          background: "#FBBD3E",
                          width: "18px",
                          height: "18px",
                          textAlign: "center",
                          fontSize: "13px",
                          borderRadius: "50%",
                        }}
                      >
                        {expiringLMK}
                      </div>
                    )}
                    {expiredLMK !== 0 && (
                      <div
                        style={{
                          padding: "1px",
                          background: "#ee0a0a",
                          width: "18px",
                          height: "18px",
                          textAlign: "center",
                          fontSize: "13px",
                          borderRadius: "50%",
                          marginLeft: "10px",
                          color: "#ffffff",
                        }}
                      >
                        {expiredLMK}
                      </div>
                    )}
                  </div>
                </div>
              </MenuItem>
              <MenuItem component={<Link to="/control_flg" />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  ФЛГ
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "20px",
                      marginLeft: "auto",
                      marginRight: "0px",
                    }}
                  >
                    {expiringFLG !== 0 && (
                      <div
                        style={{
                          padding: "1px",
                          background: "#FBBD3E",
                          width: "18px",
                          height: "18px",
                          textAlign: "center",
                          fontSize: "13px",
                          borderRadius: "50%",
                        }}
                      >
                        {expiringFLG}
                      </div>
                    )}
                    {expiredFLG !== 0 && (
                      <div
                        style={{
                          padding: "1px",
                          background: "#ee0a0a",
                          width: "18px",
                          height: "18px",
                          textAlign: "center",
                          fontSize: "13px",
                          borderRadius: "50%",
                          marginLeft: "10px",
                          color: "#ffffff",
                        }}
                      >
                        {expiredFLG}
                      </div>
                    )}
                  </div>
                </div>
              </MenuItem>
              <MenuItem component={<Link to="/control_adsm" />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  АДСМ
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "20px",
                      marginLeft: "auto",
                      marginRight: "0px",
                    }}
                  >
                    {expiringADSM !== 0 && (
                      <div
                        style={{
                          padding: "1px",
                          background: "#FBBD3E",
                          width: "18px",
                          height: "18px",
                          textAlign: "center",
                          fontSize: "13px",
                          borderRadius: "50%",
                        }}
                      >
                        {expiringADSM}
                      </div>
                    )}
                    {expiredADSM !== 0 && (
                      <div
                        style={{
                          padding: "1px",
                          background: "#ee0a0a",
                          width: "18px",
                          height: "18px",
                          textAlign: "center",
                          fontSize: "13px",
                          borderRadius: "50%",
                          marginLeft: "10px",
                          color: "#ffffff",
                        }}
                      >
                        {expiredADSM}
                      </div>
                    )}
                  </div>
                </div>
              </MenuItem>
              <MenuItem component={<Link to="/control_siz" />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  СИЗ
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "20px",
                      marginLeft: "auto",
                      marginRight: "0px",
                    }}
                  >
                    {expiringSIZ !== 0 && (
                      <div
                        style={{
                          padding: "1px",
                          background: "#FBBD3E",
                          width: "18px",
                          height: "18px",
                          textAlign: "center",
                          fontSize: "13px",
                          borderRadius: "50%",
                        }}
                      >
                        {expiringSIZ}
                      </div>
                    )}
                    {expiredSIZ !== 0 && (
                      <div
                        style={{
                          padding: "1px",
                          background: "#ee0a0a",
                          width: "18px",
                          height: "18px",
                          textAlign: "center",
                          fontSize: "13px",
                          borderRadius: "50%",
                          marginLeft: "10px",
                          color: "#ffffff",
                        }}
                      >
                        {expiredSIZ}
                      </div>
                    )}
                  </div>
                </div>
              </MenuItem>
              <MenuItem component={<Link to="/control_briefings" />}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  Инструктажи
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "20px",
                      marginLeft: "auto",
                      marginRight: "0px",
                    }}
                  >
                    {expiringINST !== 0 && (
                      <div
                        style={{
                          padding: "1px",
                          background: "#FBBD3E",
                          width: "18px",
                          height: "18px",
                          textAlign: "center",
                          fontSize: "13px",
                          borderRadius: "50%",
                        }}
                      >
                        {expiringINST}
                      </div>
                    )}
                    {expiredINST !== 0 && (
                      <div
                        style={{
                          padding: "1px",
                          background: "#ee0a0a",
                          width: "18px",
                          height: "18px",
                          textAlign: "center",
                          fontSize: "13px",
                          borderRadius: "50%",
                          marginLeft: "10px",
                          color: "#ffffff",
                        }}
                      >
                        {expiredINST}
                      </div>
                    )}
                  </div>
                </div>
              </MenuItem>
            </SubMenu>
            <MenuItem
              icon={<BeachAccessIcon />}
              component={<Link to="/vacations" />}
            >
              Отпуск
            </MenuItem>
            <MenuItem icon={<PrintIcon />} component={<Link to="/print" />}>
              Печать
            </MenuItem>
            <SubMenu defaultOpen label="История" icon={<HistoryIcon />}>
              <MenuItem component={<Link to="/history_ports" />}>
                {" "}
                Должности
              </MenuItem>
            </SubMenu>
            <MenuItem icon={<FileUpload />} component={<Link to="/excel" />}>
              Загрузить данные из Excel
            </MenuItem>
            <MenuItem icon={<ImExit />} onClick={handleLogout}>
              {" "}
              Выход
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>
    </div>
  );
};

export default Navbar;
