import React, { useEffect, useState } from 'react';
import "../Styles/tableFired.scss";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import {
  fetchFiredEmployee,
} from "../api_control.ts";
import { FiredEmployee as FiredEmpl } from "../models.ts";
import Slider from "@mui/material/Slider";


const TableFired = () => {
    const [firedEmployees, setFiredEmpl] = useState([]);
    
    useEffect(() => {
      const fetchData = async () => {
        try {
          const data = await fetchFiredEmployee();
          setFiredEmpl(data);
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
    }, []);

    const columns: GridColDef[] = [
      {
        field: "id",
        headerName: "ID",
        width: 40,
        valueGetter: (value, row) => {
          return row.id;
        },
      },
      {
        field: "fio",
        headerName: "ФИО",
        width: 250,
        type: "string",
        editable: false,
        valueGetter: (value, row) => {
          return row.fio;
        },
      },
      {
        field: "post",
        headerName: "Должность",
        width: 250,
        type: "string",
        editable: false,
        valueGetter: (value, row) => {
          if (row.mainPost != null){
            return row.mainPost.namepost;
          }
        },
      },
      {
        field: "dateemployment",
        headerName: "Дата трудоуст.",
        width: 130,
        editable: false,
        type: "date",
        valueGetter: (value, row) => {
          if (!row.dateemployment) {
            return null;
          }
          const [day, month, year] = row.dateemployment.split(".");
          return new Date(`${year}-${month}-${day}`);
        },
      },
      {
        field: "datefired",
        headerName: "Дата увольнения",
        width: 140,
        editable: false,
        type: "date",
        valueGetter: (value, row) => {
          if (!row.datefired) {
            return null;
          }
          const [day, month, year] = row.datefired.split(".");
          return new Date(`${year}-${month}-${day}`);
        },
      },
      {
        field: "reason",
        headerName: "Причина увольнения",
        width: 300,
        type: "string",
        editable: false,
      },
      {
        field: "dateedst",
        headerName: "Стаж",
        width: 100,
        editable: false,
        valueGetter: (value, row) => {
          if (!row.dateemployment) {
            return null;
          }
          const [day, month, year] = row.dateemployment.split(".");
          const startDate = new Date(`${year}-${month}-${day}`);
          const [day1, month1, year1] = row.datefired.split(".");
          const endDate = new Date(`${year1}-${month1}-${day1}`);
          const { formattedExperience } = calculateWorkExperience(
            startDate,
            endDate
          );
          return formattedExperience;
        },
      },
    ];

    const calculateWorkExperience = (startDate, endDate) => {
      const now = new Date(endDate);
      const start = new Date(startDate);

      let years = now.getFullYear() - start.getFullYear();
      let months = now.getMonth() - start.getMonth();
      let days = now.getDate() - start.getDate();

      if (months < 0) {
        years--;
        months += 12;
      }

      if (days < 0) {
        months--;
        const lastMonth = new Date(now.getFullYear(), now.getMonth(), 0);
        days += lastMonth.getDate();
      }

      const totalMonths = years * 12 + months;

      let experience = "";
      if (years > 0) {
        experience += `${years} ${
          years === 1 ? "год" : years > 1 && years < 5 ? "года" : "лет"
        }`;
      }

      if (months > 0) {
        if (experience) experience += " ";
        experience += `${months} ${
          months === 1
            ? "месяц"
            : months > 1 && months < 5
            ? "месяца"
            : "месяцев"
        }`;
      }

      if (years === 0 && months === 0 && days >= 0) {
        if (days === 0) {
          experience = "менее дня";
        } else {
          experience = `${days} ${
            days === 1 ? "день" : days > 1 && days < 5 ? "дня" : "дней"
          }`;
        }
      }

      return { formattedExperience: experience.trim(), totalMonths };
    };

    const [experienceRange, setExperienceRange] = useState([0, 60]);

    const handleRangeChange = (event, newValue) => {
      setExperienceRange(newValue);
    };

    const [filteredEmpl, setFilteredEmpl] = useState<FiredEmpl[]>([]);

    useEffect(() => {
      if (firedEmployees) {
        const filtered = firedEmployees.filter((employee: FiredEmpl) => {
          if (!employee.dateemployment) {
            return null;
          }
          const [day, month, year] = employee.dateemployment.split(".");
          const startDate = new Date(`${year}-${month}-${day}`);
          const [day1, month1, year1] = employee.datefired.split(".");
          const endDate = new Date(`${year1}-${month1}-${day1}`);
          const { totalMonths } = calculateWorkExperience(startDate, endDate);
          return (
            totalMonths >= experienceRange[0] &&
            totalMonths <= experienceRange[1]
          );
        });
        setFilteredEmpl(filtered);
      }
    }, [firedEmployees, experienceRange]);

    const marks = [
      { value: 0, label: "0 лет" },
      { value: 12, label: "1 год" },
      { value: 24, label: "2 года" },
      { value: 36, label: "3 года" },
      { value: 48, label: "4 года" },
      { value: 60, label: "5 лет" },
    ];
  
  
    return (
      <div className="fired_employee_cont">
        <div className="table_cont3">
          <div
            style={{
              width: "500px",
              marginBottom: 10,
              marginTop: 20,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="st_title">Стаж: </div>
            <Slider
              value={experienceRange}
              onChange={handleRangeChange}
              valueLabelDisplay="auto"
              min={0}
              max={60}
              marks={marks}
            />
          </div>
          <DataGrid
            rows={filteredEmpl ?? []}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            pageSizeOptions={[10]}
            disableRowSelectionOnClick
            slots={{
              toolbar: GridToolbar,
            }}
          />
        </div>
      </div>
    );
  };
  
  export default TableFired;