import { useState } from "react";
import "../Styles/dateChangeModal.scss";

const DateChangeModal = ({ onClose, onSubmit, isVisible, changedFields }) => {
  const [postDateChange, setPostDateChange] = useState('');
  const [sizDateChange, setSizDateChange] = useState('');

  const handleSubmit = () => {
    onSubmit({ postDateChange, sizDateChange });
  };

  return (
    isVisible && (
      <div className="modal">
        <div className="modal_content_dataChange">
          <span className="close" onClick={onClose}>
            &times;
          </span>
          <h3>Введите дату изменения</h3>
          {changedFields.post && (
            <div>
              <p>Изменение должности</p>
              <label htmlFor="postDateChange">Дата изменения должности:</label>
              <input
                type="date"
                id="postDateChange"
                name="postDateChange"
                value={postDateChange}
                onChange={(e) => setPostDateChange(e.target.value)}
              />
            </div>
          )}
          {changedFields.siz && (
            <div>
              <p>Изменение данных СИЗ</p>
              <label htmlFor="sizDateChange">Дата изменения данных СИЗ:</label>
              <input
                type="date"
                id="sizDateChange"
                name="sizDateChange"
                value={sizDateChange}
                onChange={(e) => setSizDateChange(e.target.value)}
              />
            </div>
          )}
          <button
            onClick={handleSubmit}
            style={{
              marginTop: "20px",
              width: "150px",
              height: "30px",
              borderRadius: "5px",
              backgroundColor: "#1976d2",
              border: "none",
              cursor: "pointer",
            }}
          >
            Подтвердить
          </button>
        </div>
      </div>
    )
  );
};

export default DateChangeModal;