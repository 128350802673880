import './Styles/main.scss';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation,
    Navigate,
    useNavigate
} from "react-router-dom";
import Employee from "./Pages/employee.tsx";
import Navbar from './Components/navbar.tsx';
import TableArea from './Components/table_area.tsx';
import Lmk from './Pages/lmk.tsx';
import Control from './Pages/control.tsx';
import Login from './Pages/login';
import { useEffect } from 'react';
import ControlFlg from './Pages/control_flg.tsx';
import ControlAdsm from "./Pages/control_adsm.tsx";
import ControlSiz from "./Pages/control_siz.tsx";
import Vacations from "./Pages/vacations.tsx";
import HistoryPosts from './Pages/history_posts.tsx';
import Employed from './Pages/employed.tsx';
import AreaTable from './Pages/area_table.tsx';
import TableFired from './Components/table_fired_employee.tsx';
import TableSiz from './Components/table_siz.tsx';
import Briefings from './Pages/briefings.tsx';
import Print from './Pages/print.tsx';
import { Provider } from 'react-redux';
import { store } from './store.ts';
import FileUpload from './Pages/fileUpload.tsx';
import Homepage from './Pages/homepage.tsx';

function NavbarWrapper() {
  const location = useLocation();
  const hideNavbar = location.pathname === '/login'; 

  const isAuthenticated = !!localStorage.getItem('token');

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated && location.pathname !== '/login') {
      navigate('/login');
    } else if (isAuthenticated && location.pathname === '/login') {
      navigate('/employee');
    }

  }, [location.pathname, navigate, isAuthenticated]);

  return (
    <div style={{ display: "flex", width: "100%" }}>
      {!hideNavbar && <Navbar />}
      <Routes>
        <Route exact path="/login" element={<Login />} />
        {isAuthenticated ? (
          <>
            <Route exact path="/employee" element={<Employee />} />
            <Route path="/fired_employee" element={<TableFired />} />
            <Route path="/siz" element={<TableSiz />} />
            <Route path="/lmk" element={<Lmk />} />
            <Route path="/area" element={<TableArea />} />
            <Route path="/control" element={<Control />} />
            <Route path="/control_flg" element={<ControlFlg />} />
            <Route path="/control_adsm" element={<ControlAdsm />} />
            <Route path="/control_siz" element={<ControlSiz />} />
            <Route path="/vacations" element={<Vacations />} />
            <Route path="/history_ports" element={<HistoryPosts />} />
            <Route path="/employed" element={<Employed />} />
            <Route path="/area_table" element={<AreaTable />} />
            <Route path="/control_briefings" element={<Briefings />} />
            <Route path='/print' element={<Print />} />
            <Route path="/excel" element={<FileUpload />} />
            <Route path="/" element={<Homepage />}/>
          </>
        ) : (
          <Route path="*" element={<Navigate to="/login" />} />
        )}
      </Routes>
    </div>
  );
}

function App() {
  return (
    <Provider store={store}>
      <Router>
        <NavbarWrapper />
      </Router>
    </Provider>
  );
}

export default App;